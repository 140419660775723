import React, { useState } from 'react';
import cx from 'classnames';
import { get } from 'lodash';
import PropTypes from 'prop-types';

import ToggleCTALinkButton from 'components/essentials/ToggleCTALinkButton';
import JBImage from 'components/essentials/JBImage';
import JBIFrameHolder from 'components/essentials/JBIFrameHolder';
import CtaButton from 'components/essentials/Cta/CtaButton';
import CtaLink from 'components/essentials/Cta/CtaLink';

import useScreen from 'hooks/useScreen';
import definition from './definition.yaml';

const getOptimizedImage = (url, w, h) => {
  if (!url || url.indexOf('imgix.net') === -1) {
    return url;
  }
  const params = `auto=compress,format&w=${w}&h=${h}&dpr=2&q=40&cs=srgb`;
  if (url.indexOf('?') > -1) {
    return `${url}&${params}`;
  }
  return `${url}?${params}`;
};

const BannerTextSection = ({
  className,
  config,
  fullWidth = false,
  variant,
}) => {
  const {
    textContent = {},
    colors = {},
    cta,
    ctaColors = {},
    toggleInputType,
    isCarousel = false,
  } = config;
  const [iFrameVisibility, setIFrameVisibility] = useState(false);

  const { isMobileOrTablet: useImageExtraWide } = useScreen();

  const {
    backgroundImage,
    icon,
    heroFancyText,
    heroFancyTextColor,
    heroLogomark,
    body,
    title,
    disclaimer,
    iconWidth = '50%',
  } = textContent;

  const colorsTextHex = get(colors, 'textHex', definition.props.colors.textHex);

  const ctaColorsBackgroundHex = get(
    ctaColors,
    'backgroundHex',
    definition.props.ctaColors.backgroundHex
  );

  const ctaColorsTextHex = get(
    ctaColors,
    'textHex',
    definition.props.ctaColors.textHex
  );

  const ctaStyles = {
    background: {
      backgroundColor: ctaColorsBackgroundHex,
    },
    text: {
      color: ctaColorsTextHex,
    },
  };

  const textContentBackgroundStyles = backgroundImage
    ? {
        backgroundImage: `url(${getOptimizedImage(
          backgroundImage,
          useImageExtraWide ? 798 : 512,
          useImageExtraWide ? 358 : 531
        )})`,
      }
    : {};
  return (
    <div
      className={cx(
        `px-5 py-8 flex flex-none h-full w-full flex-col justify-center object-cover bg-cover md:px-[3.333vw] md:py-8 lg:[text-align:initial] lg:min-w-[480px] lg:px-[3.333vw] lg:py-0 lg:w-[480px] min-[1440px]:min-w-[540px] min-[1440px]:w-[540px] ${className}`,
        {
          'lg:min-w-full lg:w-full': fullWidth,
          'pl-[calc(3.333vw_+_20px)]': isCarousel,
          '!p-[55px] !w-full !min-w-full !rounded-[.5rem] md:max-[1023px]:!rounded-none':
            variant === 'ShopPagePromotionPanel',
        }
      )}
      style={textContentBackgroundStyles}
    >
      {icon && (
        <img
          className="mb-5 w-1/2 max-w-[260px] md:max-w-[50%]"
          src={icon}
          alt="Icon"
          style={{ width: iconWidth }}
        />
      )}
      {heroLogomark && (
        <JBImage className="mb-5" src={heroLogomark} width={400} height={200} />
      )}
      {heroFancyText && (
        <span
          className="mb-5 text-[1.4rem] non-italic font-normal md:text-[1.4rem]"
          style={{
            color: heroFancyTextColor,
          }}
        >
          {heroFancyText}
        </span>
      )}
      <h1
        className="text-4xl font-bold mb-3 md:mb-5"
        style={{
          color: colorsTextHex,
        }}
      >
        {title}
      </h1>
      {body && (
        <p
          className="text-base font-bold mb-6 md:text-lg md:font-bold"
          style={{ color: colorsTextHex }}
        >
          {body}
        </p>
      )}
      {!!cta && cta.content && (!cta.embedCode || !cta.embedCode.enabled) && (
        <div>
          <CtaLink
            style={ctaStyles}
            to={cta.linkTo}
            styling={cta.style}
            color="brand"
          >
            {cta.content}
          </CtaLink>
        </div>
      )}
      {!!toggleInputType && (
        <ToggleCTALinkButton
          style={ctaStyles}
          toggleInputType={toggleInputType}
        />
      )}
      {!toggleInputType && !!cta && cta.embedCode && cta.embedCode.enabled && (
        <div>
          {iFrameVisibility ? (
            <JBIFrameHolder
              isOpen={iFrameVisibility}
              onClose={setIFrameVisibility}
              script={cta.embedCode.value || ''}
            />
          ) : null}
          <CtaButton
            style={ctaStyles}
            onClick={() => {
              setIFrameVisibility(true);
            }}
            color={cta.color || 'brand'}
            styling={cta.style || 'solid-button'}
          >
            {cta.content}
          </CtaButton>
        </div>
      )}
      {disclaimer && (
        <p
          className="mt-4 mb-0 opacity-75 md:mt-6 text-xs font-bold"
          style={{ color: colorsTextHex }}
        >
          {disclaimer}
        </p>
      )}
    </div>
  );
};

BannerTextSection.propTypes = {
  config: PropTypes.shape({
    colors: PropTypes.shape({
      textHex: PropTypes.string,
    }),
    cta: PropTypes.shape({
      color: PropTypes.string,
      content: PropTypes.string,
      embedCode: PropTypes.shape({
        enabled: PropTypes.bool,
        value: PropTypes.string,
      }),
      linkTo: PropTypes.string,
      style: PropTypes.string,
    }),
    ctaColors: PropTypes.shape({
      backgroundHex: PropTypes.string,
      textHex: PropTypes.string,
    }),
    isCarousel: PropTypes.bool,
    textContent: PropTypes.shape({
      backgroundImage: PropTypes.string,
      body: PropTypes.string,
      disclaimer: PropTypes.string,
      heroFancyText: PropTypes.string,
      heroFancyTextColor: PropTypes.string,
      icon: PropTypes.string,
      iconWidth: PropTypes.string,
      title: PropTypes.string,
    }),
    toggleInputType: PropTypes.object,
  }),
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(['ShopPagePromotionPanel']),
};

export default BannerTextSection;
