import loadable from '@loadable/component';

import isNewConsumerApp from 'commons/isNewConsumerApp';
import Passthrough from 'components/consumer/Passthrough';

const AboveTheFoldServerRendererLegacy = loadable(() =>
  import('./AboveTheFoldServerRendererLegacy')
);

// This component conditionally only renders children in the legacy consumer
// app, but in the new app will always render children for all devices as
// required by React 18.
const AboveTheFoldServerRenderer = isNewConsumerApp
  ? Passthrough
  : AboveTheFoldServerRendererLegacy;

export default AboveTheFoldServerRenderer;
