import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import ToggleSwitch from 'components/consumer/ToggleSwitch';
import IconQuickship from 'components/icons/iconQuickship';
import QuickshipZipCodeEditLocation from 'components/essentials/QuickshipZipCodeEditLocation';

const QuickShipFilter = ({
  isChecked,
  isTablet,
  minHeight = true,
  onChange = () => undefined,
  onUserLocationChanged = null,
  withLocation = false,
  toggleVisible,
}) => (
  <div
    className={classNames(
      'flex flex-row justify-start [&_button]:border-0 [&_button]:bg-transparent',
      {
        'rounded-[3px] justify-start flex-row': withLocation,
        'md:min-h-[51px]': withLocation && minHeight,
        'flex-wrap': withLocation && isTablet,
      }
    )}
  >
    <div
      className={classNames(
        'flex items-center [&_span[class*=MuiSwitch-root]]:mt-1 [&_label]:mr-0',
        {
          'h-[2.8125rem]': minHeight,
          '[&_label]:mr-1': withLocation,
          'flex-col !items-start': withLocation && isTablet,
        }
      )}
    >
      {isTablet ? (
        <>
          <div
            className={classNames('text-gray', {
              'text-sm font-bold leading-[20px] text-gray': withLocation,
            })}
          >
            Ready to Ship
          </div>
          <ToggleSwitch
            isChecked={isChecked}
            onClick={() => onChange(!isChecked)}
          >
            <span
              className={classNames(
                'flex items-center md:text-sm md:font-bold text-sm font-bold text-gray relative -top-0.5 [&_svg]:relative [&_svg]:top-0.5',
                {
                  active: isChecked,
                }
              )}
            >
              <IconQuickship />
            </span>
          </ToggleSwitch>
        </>
      ) : (
        <ToggleSwitch
          isChecked={isChecked}
          onClick={() => onChange(!isChecked)}
          toggleVisible={toggleVisible}
        >
          <span
            className={classNames(
              'flex items-center md:text-sm md:font-bold text-sm font-bold text-gray relative -top-0.5 [&_svg]:relative [&_svg]:top-0.5 gap-0.5',
              {
                active: isChecked,
              }
            )}
          >
            <IconQuickship />
            <span
              className={classNames('relative top-0.5 text-gray', {
                'text-sm font-bold leading-[20px] text-gray': withLocation,
              })}
            >
              Ready to Ship
            </span>
          </span>
        </ToggleSwitch>
      )}
    </div>

    <div
      className={classNames('flex items-center', {
        'h-5': isTablet,
        'h-[2.8125rem]': !isTablet && minHeight,
      })}
    >
      {!!withLocation && (
        <>
          {!isTablet && <span>&nbsp;</span>}
          <QuickshipZipCodeEditLocation
            onLocationChanged={onUserLocationChanged}
          />
        </>
      )}
    </div>
  </div>
);

QuickShipFilter.propTypes = {
  isChecked: PropTypes.bool,
  isTablet: PropTypes.bool,
  minHeight: PropTypes.bool,
  onChange: PropTypes.func,
  onUserLocationChanged: PropTypes.func,
  withLocation: PropTypes.bool,
  toggleVisible: PropTypes.bool,
};

export default QuickShipFilter;
