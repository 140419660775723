/* eslint no-underscore-dangle: 0 */
import axios from 'axios';
import { isEmpty } from 'lodash';

import isClient from 'commons/isClient';
import getEnvValue from 'commons/getEnvValue';
import getAlexandriaUrl from 'commons/getAlexandriaUrl';
import { dispatchCustomEvent } from 'hooks/useCustomEvent';
import requestIdleCallback from 'commons/requestIdleCallback';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import { DEFAULTS_FIELD_VALUES } from './constants';

export const ALL_OPTION_UPDATED_EVENT_TYPE = 'allOptionsUpdated';

class AllOptions {
  constructor() {
    this.ALL_OPTIONS_CACHE = null;
    this.ALL_OPTIONS_BY_VALUE_KEY = null;
    this.ALL_OPTIONS_RESP = null;
    this.cacheLoading = false;
    setTimeout(() => {
      requestIdleCallback(() => {
        this.fetchAllOptions();
      });
    }, 8000);
  }

  static setAllOptionsByValue = allOpts => {
    if (isEmpty(allOpts)) {
      return null;
    }

    const finalAllOptsByMaterial = {};
    Object.keys(allOpts).forEach(key => {
      const val = allOpts[key];
      if (val.value) {
        finalAllOptsByMaterial[
          val.value
            .toLowerCase()
            .trim()
            .split(' ')
            .join('-')
        ] = {
          ...val,
        };
      }
    });
    return finalAllOptsByMaterial;
  };

  fetchAllOptions = async (testCache = null, refreshCache = false) => {
    try {
      if (
        (this.cacheLoading || (this.ALL_OPTIONS_CACHE && !refreshCache)) &&
        !testCache
      ) {
        return;
      }

      const GRAPHQL_BASE_URL = getAlexandriaUrl();
      this.cacheLoading = true;
      const isTestEnv = getEnvValue('JB_TEST_ENV') === 'jest'; // Temporary test handling till correct method is added
      // Moving it out of Apollo cache processing
      const { data } =
        isTestEnv || testCache
          ? testCache
          : (isClient() && window.__OPTIONS_RESP__) ||
            (await axios({
              url: GRAPHQL_BASE_URL,
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
              data: {
                query: `query { 
          allActiveOptionValues: getAllActiveOptionsValues { 
            id
            value
            image
            cover
            description
            pet_friendly
            price_type
            tier
            sku
            popular_exclude
            exclude_from_sale
            performance_fabric
            sustainable_fabric
            color
            is_active
            group {
              id
              cleaning_code
              description
              guide_description
              material
              material_description
              name
              sort_order
              pet_friendly
              short_description
              star
              images {
                id
                name
                description
                sort_order
              }
            }
          }
          }`,
              },
            }));
      if (isClient() && window.__OPTIONS_RESP__) {
        delete window.__OPTIONS_RESP__;
      }
      this.ALL_OPTIONS_RESP = { data };

      if (!data?.data?.allActiveOptionValues?.length) {
        return;
      }

      const optsValues = data?.data?.allActiveOptionValues ?? [];
      const allOptsByValue = {};
      this.ALL_OPTIONS_CACHE = optsValues.reduce((acc, val) => {
        if (val) {
          const newVal = {
            ...DEFAULTS_FIELD_VALUES,
            ...val,
          };
          acc[val.id] = {
            ...newVal,
          };
          if (newVal.value) {
            allOptsByValue[
              newVal.value
                .toLowerCase()
                .trim()
                .split(' ')
                .join('-')
            ] = { ...newVal };
          }
        }
        return acc;
      }, {});
      this.ALL_OPTIONS_BY_VALUE_KEY = allOptsByValue;
      this.cacheLoading = false;
      if (isClient()) {
        dispatchCustomEvent(ALL_OPTION_UPDATED_EVENT_TYPE, {});
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('failed to fetch allOptions', e.message);
    }
  };

  getAllOptions = (skip = false) => {
    if (!skip && !this.ALL_OPTIONS_CACHE) {
      this.fetchAllOptions();
    }

    return [
      this.cacheLoading,
      this.ALL_OPTIONS_CACHE,
      this.ALL_OPTIONS_BY_VALUE_KEY,
    ];
  };

  getAllOptionsResponse = () => this.ALL_OPTIONS_RESP;
}

const AllOptionsInstance = isNewConsumerApp ? null : new AllOptions();

export default AllOptionsInstance;
