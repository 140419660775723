export const DEFAULTS_FIELD_VALUES = {
  color: '',
  description: '',
  group: null,
  is_active: 1,
  performance_fabric: 0,
  pet_friendly: 0,
  popular_exclude: 0,
  price_type: null,
  sustainable_fabric: 0,
  tier: 0,
};
