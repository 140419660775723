import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import BrowserOnly from 'components/consumer/BrowserOnly';
import UpdateZipcodeSidebar from 'components/consumer/UpdateZipcodeSidebar';
import useZipCode from 'global-state/zipCode/useZipCode';
import useIsZipCodeFromUserServiceable from 'global-state/isZipCodeFromUserServiceable/useIsZipCodeFromUserServiceable';
import useZipCodeFromUser from 'global-state/zipCodeFromUser/useZipCodeFromUser';
import useUserLocation from 'global-state/userLocation/useUserLocation';
import useUserLocationLoading from 'global-state/userLocationLoading/useUserLocationLoading';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import QuickshipZipCodeEditLocationUI from './QuickshipZipCodeEditLocationUI';

const QuickshipZipCodeEditLocation = ({ onLocationChanged = null }) => {
  const zipCode = useZipCode();
  const zipCodeFromUser = useZipCodeFromUser();
  const userLocation = useUserLocation();
  const isLocationLoading = useUserLocationLoading();
  const [isUpdateZipcodeOpen, setIsUpdateZipcodeOpen] = useState(false);

  // TODO: After all legacy pseudo-category pages have been upgraded to use
  // ProductListingPage, we should remove this useEffect and the
  // onLocationChanged prop - this prop is not used by the new version.
  useEffect(() => {
    if (!isNewConsumerApp && !isEmpty(userLocation) && onLocationChanged) {
      onLocationChanged();
    }
    // This is either an issue with apollo clients reactive variables
    // or how we're using it with jbreactivevars. We're hoping to replace
    // this with an alternative state management library soon.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLocation]);

  const toggleUpdateZipCodeSidebar = useCallback(() => {
    setIsUpdateZipcodeOpen(prevIsUpdateZipcodeOpen => !prevIsUpdateZipcodeOpen);
  }, []);

  const isZipCodeFromUserServiceable = useIsZipCodeFromUserServiceable();

  const { country: locationCountry } = userLocation;
  const isUserInUS = locationCountry === 'United States';

  return (
    <>
      <BrowserOnly>
        <UpdateZipcodeSidebar
          isZipcodeFormOpen={isUpdateZipcodeOpen}
          onClose={toggleUpdateZipCodeSidebar}
        />
      </BrowserOnly>

      <QuickshipZipCodeEditLocationUI
        isLoading={isLocationLoading}
        isUserInUS={isUserInUS}
        isZipCodeFromUserServiceable={isZipCodeFromUserServiceable}
        toggleUpdateZipCodeSidebar={toggleUpdateZipCodeSidebar}
        zipCode={zipCode}
        zipCodeFromUser={zipCodeFromUser}
      />
    </>
  );
};

QuickshipZipCodeEditLocation.propTypes = {
  onLocationChanged: PropTypes.func,
};

export default React.memo(QuickshipZipCodeEditLocation);
