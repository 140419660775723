import React from 'react';
import PropTypes from 'prop-types';

import ProgressLoaderDots from 'components/essentials/ProgressLoaderDots/ProgressLoaderDots';
import isNewConsumerApp from 'commons/isNewConsumerApp';
import useIsBrowser from 'hooks/useIsBrowser';

const QuickshipZipCodeWrapper = ({ children }) => (
  <div className="!text-base !leading-[20px] flex-1">{children}</div>
);

QuickshipZipCodeWrapper.propTypes = {
  children: PropTypes.node,
};

const QuickshipZipCodeEditLocationUI = ({
  isLoading = false,
  isUserInUS = true,
  isZipCodeFromUserServiceable = true,
  toggleUpdateZipCodeSidebar,
  zipCode,
  zipCodeFromUser,
}) => {
  const isBrowser = useIsBrowser();

  if (isLoading || (!isBrowser && isNewConsumerApp)) {
    return (
      <QuickshipZipCodeWrapper>
        <ProgressLoaderDots inline grey />
      </QuickshipZipCodeWrapper>
    );
  }

  if (
    (!!zipCodeFromUser && !isZipCodeFromUserServiceable) ||
    !isUserInUS ||
    !zipCode
  ) {
    return (
      <QuickshipZipCodeWrapper>
        <button
          className="items-center flex focus-visible:outline-offset-4 focus:outline-0 text-brand font-bold underline hover:underline"
          onClick={toggleUpdateZipCodeSidebar}
          type="button"
          aria-label="Add your location"
        >
          Add zip
        </button>
      </QuickshipZipCodeWrapper>
    );
  }

  return (
    <QuickshipZipCodeWrapper>
      to{' '}
      <button
        className="items-center focus-visible:outline-offset-4 focus:outline-0 text-brand font-bold underline hover:underline"
        onClick={toggleUpdateZipCodeSidebar}
        type="button"
        aria-label="Update your location"
      >
        {zipCode}
      </button>
    </QuickshipZipCodeWrapper>
  );
};

QuickshipZipCodeEditLocationUI.propTypes = {
  isLoading: PropTypes.bool,
  isUserInUS: PropTypes.bool,
  isZipCodeFromUserServiceable: PropTypes.bool,
  toggleUpdateZipCodeSidebar: PropTypes.func,
  zipCode: PropTypes.string,
  zipCodeFromUser: PropTypes.string,
};

export default React.memo(QuickshipZipCodeEditLocationUI);
